<template>
    <section class="content">
        <table class="table table-hover" ref="tblkelas">
        <thead>
            <tr>
            <th>NAMA KELAS</th>
            <th>TINDAKAN</th>
            </tr>
        </thead>
        <tbody @click="handleClick"></tbody>
        </table>
    </section>
    <!-- /.content -->
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
    name: "",
    data() {
        return {
        errors: [],
        method: "",
        roles: "",
        formTitle: "Tambah Kelas",
        form: {
            kelas: "",
        },
        };
    },
    created: function () {
        this.roles = this.$route.meta.roles;
        console.log("load initial data", this.$route);
    },
    methods: {
        handleClick(e) {
        if (e.target.matches(".link-role")) {
            this.$router.push({ path: "/permit/" + e.target.dataset.id });
            return false;
        }
        if (e.target.closest("button")) {
            var btnEl = $(e.target).closest("button").get(0);
            if (btnEl.dataset.action == "detail") {
                var id = btnEl.dataset.id;
                this.$router.push('/rekap-ujian/mapel/'+id+'/'+this.$route.params.id);
            }
            return false;
        }
        },
    },
    mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tblkelas, {
        title: "",
        roles: ["read"],
        ajax: "/akademik/ujian/per_kelas",
        columns: [
            { data: "kelas" },
            { data: "action",
            render: function (data, type, row, meta) {
                return `<div class="btn-group">
                <button type="button" class="btn btn-sm btn-outline-info" data-action="detail" data-id="` + row.id +`"><i class="fas fa-eye"></i> Detail Mapel</button>
                </div>`
            } },
        ],
        paramData: function(d) {
        if (self.$route.params.id) {
            d.program_id = self.$route.params.id;
        } else {
            d.program_id = 0;
        }
        },
        // filterBy: [0],
        filter: false,
        rowCallback: function (row, data) {
        // $('td:eq(2)', row).html('<a type="button" class="btn btn-primary" data-id="'+data.id+'" data-branch="'+data.branch_id+'">Data Siswa</a>');
        },
    });
},
};
</script>
